import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from '@app/components/Flexbox';
import { css } from '@emotion/css';
import { Input, Spin, Radio } from 'antd';
import $ from 'jquery';
import { colorBorderGrey } from '@app/styles/base';

const apiBaseUrl = process.env.REACT_APP_API_URL;

const { Search } = Input;

const resultStyle = css`
    border: 1px solid ${colorBorderGrey};
    border-radius: 3px;
    margin: 6px 0;
    padding: 8px 15px;
    font-size:
`;

const nameStyle = css`
    font-weight: bold;
`;

const searchStyles = css`
    width: 100%;
`;

const spinStyles = css`
    margin-top: 15px !important;
`;

const radioStyles = css`
    margin-left: 15px !important;
    label {
        font-weight: normal;
    }
`;


export default class PeopleModal extends React.Component {
    static propTypes = {
        isMobileOrTablet: PropTypes.bool
    };

    static defaultProps = {
        isMobileOrTablet: false
    };

    constructor(props) {
        super(props);

        this.state = {
            results: null,
            searching: false,
            searchKey: 'name_last'
        }
    }

    onSearch = (value) => {
        this.setState({ results: [], searching: true });
        $.post({
            url: `${apiBaseUrl}/db/people/_query`,
            data: JSON.stringify({
                [this.state.searchKey]: value
            }),
            success: (function (response) {
                console.log(response);
                let results = null;
                if (response.status === 200) {
                    results = response.result;
                }
                this.setState({ results, searching: false });
            }).bind(this),
            error: (function(err) {
                console.log(err);
                this.setState({ searching: false });
            }).bind(this),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true
        });
    };

    onSearchKeyChange = (e) => {
        this.setState({ searchKey: e.target.value });
    };

    renderMobileResult = (person) => {
        return (
            <Flexbox key={person.id} flexGrow={1} flexDirection={'column'} className={resultStyle}>
                <Flexbox className={nameStyle}>
                    {`${person.name_last}, ${person.name_first}`}
                </Flexbox>
                <Flexbox>
                    <em>{person.org}</em>
                </Flexbox>
                <Flexbox>
                    <a href={`mailto:${person.email}`}>{person.email}</a>
                </Flexbox>
                <Flexbox>
                    {person.phone}
                </Flexbox>
            </Flexbox>
        )
    };

    renderResult = (person) => {
        return (
            <Flexbox key={person.id} flexGrow={1} flexDirection={'column'} className={resultStyle}>
                <Flexbox flexDirection={'row'} flexGrow={1} justifyContent={'space-between'}>
                    <Flexbox className={nameStyle}>
                        {`${person.name_last}, ${person.name_first}`}
                    </Flexbox>
                    <Flexbox>
                        <a href={`mailto:${person.email}`}>{person.email}</a>
                    </Flexbox>
                </Flexbox>
                <Flexbox flexDirection={'row'} flexGrow={1} justifyContent={'space-between'}>
                    <Flexbox>
                        {person.org}
                    </Flexbox>
                    <Flexbox>
                        {person.phone}
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        );
    };

    renderResults = () => {
        let results = [];

        if (this.state.results !== null && !this.state.searching) {
            this.state.results.forEach(person => {
                const result = this.props.isMobileOrTablet ? this.renderMobileResult(person) : this.renderResult(person);
                results.push(result);
            });

            if (results.length === 0) {
                results = <span>No results.</span>
            }
        }

        return results;
    };

    render() {
        const RadioComponent = this.props.isMobileOrTablet ? Radio.Button : Radio;
        return(
            <Flexbox flexDirection={'column'} className={css`width:100%;`}>
                <Flexbox className={css`margin-bottom: 15px; flex-wrap:wrap;`}>
                    <span>Search by:</span>
                    <Radio.Group onChange={this.onSearchKeyChange} value={this.state.searchKey} className={radioStyles}>
                        <RadioComponent value={'name_last'}>last name</RadioComponent>
                        <RadioComponent value={'name_first'}>first name</RadioComponent>
                        <RadioComponent value={'phone'}>phone number</RadioComponent>
                    </Radio.Group>
                </Flexbox>
                <Flexbox flexGrow={1} className={css`margin-bottom: 10px;`}>
                    <Search
                        placeholder="Search ..."
                        onSearch={this.onSearch}
                        className={searchStyles}
                    />
                </Flexbox>
                {this.state.searching && <Spin className={spinStyles}/>}
                <Flexbox flexDirection={'column'} >
                    {this.renderResults()}
                </Flexbox>
            </Flexbox>
        )
    }
}