import { css } from '@emotion/css';

/**
 * This file defined based styles and style variables
 * to be used in styling components via emotion css-in-js.
 */

export const colorBlue = '#194173';
export const colorBlueBright = '#3D94D1';
export const colorBlueLight = '#7DB2D5';
export const colorBlueLightest = '#BADDF5';
export const colorGreen = '#00BD70';
export const colorGreenDark = '#0C7048';
export const colorGreenLight = '#AAE6C4';
export const colorGrey = '#9E9E9E';
export const colorBorderGrey = '#DDD';
export const colorLightGrey = '#bdbdbd';
export const colorBlack = '#0D1B2A';

export const textColor = css`${colorBlack}`;

export const baseLinkStyles = css`
    color: ${colorBlue};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;