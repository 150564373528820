import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Flexbox from '@app/components/Flexbox';
import { css } from '@emotion/css';
import { Form, Input, Button, Checkbox, Alert } from 'antd';
import $ from 'jquery';

const { TextArea } = Input;

const apiBaseUrl = process.env.REACT_APP_API_URL;

const bodyContainerStyle = css`
    width: 100%;
`;

export default class FeedbackModal extends React.Component {
    static propTypes = {
        email: PropTypes.string,
        onCancel: PropTypes.func
    };

    render() {
        return(
            <Flexbox flexDirection={'column'} className={bodyContainerStyle}>
                <FeedbackForm email={this.props.email}/>
            </Flexbox>
        );
    }
}

const FeedbackForm = (props) => {
    const [form] = Form.useForm();
    const [anon, setAnon] = useState(false);
    const [alert, setAlert] = useState({ type: null, message: ''});

    const toggleAnonCheck = (e) => {
        const value = e.target.checked;
        setAnon(value);
    };

    const onFinish = (values) => {
        values.anonymous = anon;
        $.post({
            url: `${apiBaseUrl}/feedback`,
            data: JSON.stringify(values),
            success: (function (response) {
                form.resetFields();
                setAlert({ type: 'success', message: 'Thank you for submitting feedback'});
            }),
            error: (function(err) {
                console.log(err);
                setAlert({ type: 'error', message: 'Error submitting feedback'});
            }),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true
        });
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout={'vertical'}
            initialValues={{
                email: props.email
            }}
        >
            <Form.Item
                name="anonymous"
            >
                <Checkbox
                    onChange={toggleAnonCheck}
                    checked={anon}
                >
                    Send comments anonymously
                </Checkbox>
            </Form.Item>
            {!anon &&
            <Form.Item
                label="Your email address"
                name="email"
                rules={
                    [
                        { required: true, message: 'Please enter your email' }
                    ]
                }
            >
                <Input disabled/>
            </Form.Item>
            }
            <Form.Item
                label="Your comments"
                name="text"
                rules={
                    [
                        { required: true, message: 'Please enter your feedback' }
                    ]
                }
            >
                        <TextArea
                            rows={4}
                        />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Send
                </Button>
            </Form.Item>
            {alert.type !== null &&
            <Alert message={alert.message} type={alert.type} showIcon/>
            }
        </Form>
    )
};
