import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from '@app/components/Flexbox';
import { css } from '@emotion/css';
import { Button } from 'antd';

import Icon from '@app/components/Icon';
import { colorBlue } from '@app/styles/base';

const containerStyles = css`
    padding: 0 30px 100px 30px;
`;
const titleStyles = css`
    font-size: 28px;
    margin-bottom: 15px;
`;
const bodyStyles = css`
    padding-top: 10px;
    .about-body {
        .description .main-link {
            font-size: 1.7rem;
        }
        .drop-item .drop-link {
            font-size: 1.6rem;
            margin-bottom: 5px;
        }
        .description, .drop-item {
            font-size: 1.5rem;
        }
    }
`;

const footerStyles = css`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px 10px;
    background-color: #fff;
`;
const iconStyles = css`
    padding-right: 10px;
    color: ${colorBlue};
`;
 
const MobileModal = (props) => {
    const cancelLabel = props.cancelLabel ?? 'Cancel';
    const [isPinnedState, setIsPinned] = React.useState(props.isPinned);

    const togglePinned = () => {
        setIsPinned(!isPinnedState);
        props.togglePinned(props.tileKey)
    }

    return (
        <Flexbox className={containerStyles} flexDirection={"column"}>
            <Flexbox className={titleStyles}>
                {props.togglePinned &&
                <span onClick={togglePinned} className={iconStyles}>
                    <Icon
                        icon="pushpin"
                        solid={isPinnedState}
                    />
                </span>
                }
                {props.title}
            </Flexbox>
            <Flexbox className={bodyStyles}>
                {props.modalBody}
            </Flexbox>
            <Flexbox className={footerStyles} justifyContent={"center"}>
                <Button onClick={props.onCancel} size={'large'}>{cancelLabel}</Button>
            </Flexbox>
        </Flexbox>
    );
}

MobileModal.propTypes = {
    title: PropTypes.string,
    onCancel: PropTypes.func,
    modalBody: PropTypes.object,
    footer: PropTypes.object,
    cancelLabel: PropTypes.string,
    pinned: PropTypes.object,
    tileKey: PropTypes.number,
    togglePinned: PropTypes.func, // for about type modals on mobile
}

export default MobileModal;