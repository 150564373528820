import React from 'react'
import $ from 'jquery'
import { css } from '@emotion/css'
import { Flex, Box } from 'rebass'
import { Spin } from 'antd'

import TitleBar from '@app/components/TitleBar'

const styles = css`
    table {
        width: 100%;
        margin: 2rem;
        tr:hover td {
            border-color: #ccc;
        }
        td {
            padding: 0.25rem 0.5rem;
            border-bottom: 1px solid #eee;
        }
        td.title {
            max-width: 7rem;
        }
        td.subject {
            font-style: italic;
        }
    }
`

const NewsArchive = ({ apiUrl }) => {

    const [archive, setArchive] = React.useState(undefined)

    React.useEffect(() => {
        if (archive !== undefined) {
            return
        }
        $.get({
            url: `${apiUrl}/newsletter-archive`,
            success: (function (response) {
                setArchive(response || [])
            }).bind(this),
            error: (function(err) {
                console.error(err)
                setArchive([])
            }).bind(this),
            xhrFields: {
                withCredentials: true,
            },
            crossDomain: true,
        })
    })

    if (archive === undefined) {
        return (
            <Flex flexDirection="column" flex={1} justifyContent="center" alignItems="center">
                <Spin/>
            </Flex>
        );
    }

    return (
        <Box className={`${styles}`}>
            <TitleBar id="nav">
                <h1 id="title" className="title">
                    News Archive
                </h1>
            </TitleBar>
            <table>
                <tbody>
                    {archive.map(row => (
                        <tr>
                            <td className="title"><a href={row.url} target="_blank">{row.title}</a></td>
                            <td className="subject">{row.subject}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Box>
    )
}
export default NewsArchive