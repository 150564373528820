import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Flexbox from '@app/components/Flexbox';
import { css } from '@emotion/css';
import { Form, Input, Select, Button, Alert } from 'antd';
import $ from 'jquery';

const { TextArea } = Input;
const { Option } = Select;

const apiBaseUrl = process.env.REACT_APP_API_URL;

const bodyContainerStyle = css`
    width: 100%;
`;


export default class ReportBugModal extends React.Component {
    static propTypes = {
        email: PropTypes.string,
        name: PropTypes.string,
        onCancel: PropTypes.func
    };

    render() {
        return (
            <Flexbox flexDirection={'column'} className={bodyContainerStyle}>
                <ReportBugForm email={this.props.email} name={this.props.name}/>
            </Flexbox>
        )
    }
}

const ReportBugForm = (props) => {
    const [form] = Form.useForm();
    const [alert, setAlert] = useState({ type: null, message: ''});

    const onFinish = (values) => {

        values.name = props.name;
        $.post({
            url: `${apiBaseUrl}/svn-ticket`,
            data: JSON.stringify(values),
            success: (function (response) {
                form.resetFields();
                setAlert({ type: 'success', message: 'Thank you! Your submission has been successful'});
            }),
            error: (function(err) {
                console.log(err);
                setAlert({ type: 'error', message: 'Error submitting ticket'});
            }),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true
        });
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout={'vertical'}
            initialValues={{
                email: props.email
            }}
        >
            <Form.Item
                label="Your email address"
                name="email"
                rules={[{ required: true, message: 'Please input your email address' }]}
            >
                <Input disabled/>
            </Form.Item>
            <Form.Item
                label="Category"
                name="category"
                rules={[{ required: true, message: 'Please select a category' }]}
            >
                <Select
                    placeholder="Select a category"
                >
                    <Option value="ARM - Campaigns and Facilities Access">Campaigns and Accessing ARM Facilities</Option>
                    <Option value="ADC Discovery & Distribution">Finding ARM Data</Option>
                    <Option value="ARM - Instruments and Data Products">Instruments and VAPs</Option>
                    <Option value="ARM - DQ Tools Support">Data Quality Issues</Option>
                    <Option value="ARM - Website">ARM Website</Option>
                    <Option value="ARM - User Account Support">ARM User Account</Option>
                    <Option value="ARM - General Help Desk">Other</Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="Your description of the issue"
                name="description"
                rules={[{ required: true, message: 'Please enter a description of your issue' }]}
            >
                <TextArea
                    rows={4}
                />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Create ServiceNow Ticket
                </Button>
            </Form.Item>
            {alert.type !== null &&
            <Alert message={alert.message} type={alert.type} showIcon/>
            }
        </Form>
    )
};
