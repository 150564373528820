import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from '@app/components/Flexbox';
import { css } from '@emotion/css';
import { useMediaQuery } from 'react-responsive';

import ListItem from '@app/components/sidebar/ListItem';
import Logo from '@app/components/Logo';
import { colorBlue } from '@app/styles/base';

const sidebarStyles = css`
    background-color: ${colorBlue};
    padding: 20px 8px 16px 8px;
    color: white;
    max-width: 190px;
    min-width: 190px;
`;

const mobileSidebarStyles = css`
    max-width: 70%;
    min-width: 70%;
`;
const tabletSidebarStyles = css`
    max-width: 300px;
    min-width: 300px;
`;

const listStyles = css`
    margin-bottom: 20px;
`;

const containerStyles = css`
    z-index:50;
`;

const mobileContainerStyles = css`
    position: fixed;
    z-index: 101;
    width: 100%;
    height: calc(100% - 40px);
    overflow: auto;
    top: 40px;
`;

const sidebarFooterStyles = css `
    border-top: 1px solid #fff;
    margin-top: 20px;
    padding-top: 20px;
`;


const Sidebar = (props) => {
    const baseTileItemProps = {
        isMobileOrTablet: props.isMobileOrTablet,
        toggleModal: props.toggleModal,
        toggleSidebar: props.toggleSidebar
    };

    const isTablet = useMediaQuery({ minWidth: 480, maxWidth: 900});

    const onContainClick = (e) => {
        if (e.target.className && e.target.className.length > 0 && e.target.className.indexOf('sidebar-container') > -1) {
            props.toggleSidebar();
        }
    }

    const toggleReportBugModal = () => {
        props.toggleSidebar();
        props.toggleModal('bug');
    }

    const toggleFeedbackModal = () => {
        props.toggleSidebar();
        props.toggleModal('feedback');
    }

    const renderPinnedList = () => {
        const list = [];
        props.pinned.forEach(item => {
            let key = item.key;
            let label = item.code;
            if (item.prefix) {
                key = `${item.prefix}-${item.code}`;
                label = `${item.prefix} ${item.code}`;
            }

            list.push(
                <ListItem
                    key={key}
                    text={label}
                    icon="bookmark"
                    tileProps={item}
                    url={item.url}
                    onClick={item.onClick}
                    {...baseTileItemProps}
                />
            )
        });

        return list;
    };

    const renderMenuItems = (menuItems) => {
        const list = [];

        menuItems.forEach(item => {
            let key = item.label;
            let label = item.label;
            let url = item.url;
            let onClick = false;
            let tileProps = null;

            switch(item.menu_item_type) {
                case 'link':
                    break;
                case 'post':
                    if (item.post.post_type === 'tile') {
                        let id = item.post.ID;
                        props.tiles.forEach(tile => {
                            if (tile.key === id) {
                                tileProps = tile;
                                url = tile.url;
                                onClick = tile.onClick;
                            }
                        });
                    } else {
                        url = item.post.guid;
                    }
                    break;
            }

            list.push(
                <ListItem
                    key={key}
                    text={label}
                    iconClass={item.icon}
                    tileProps={tileProps}
                    url={url}
                    onClick={onClick}
                    {...baseTileItemProps}
                />
            )
        });

        return list;
    };

    const renderMenu = (menu) => {
        let list = [];
        let url = null;
        switch(menu.acf.menu_item_type) {
            case 'pinned':
                list = renderPinnedList();
                break;
            case 'link':
                url = menu.acf.url;
                break;
            case 'header':
                const menuItems = menu.acf.menu_items ? menu.acf.menu_items : []
                list = renderMenuItems(menuItems);
                break;
        }

        let listClassStyles = props.isMobileOrTablet ? null : listStyles;

        return (
            <Flexbox className={`${listClassStyles} ${css`flex-shrink:0`}`} key={menu.id}>
                <ListItem
                    text={menu.title.rendered}
                    url={url}
                    iconClass={menu.acf.icon}
                    solid
                    {...baseTileItemProps}
                >
                    {list}
                </ListItem>
            </Flexbox>
        )
    };

    const renderMobileFooter = () => {
        return (
            <Flexbox flexDirection={'column'} className={sidebarFooterStyles}>
                <ListItem
                    text={props.userData.name}
                    iconClass={"fas fa-user-circle"}
                    {...baseTileItemProps}
                >
                    <ListItem
                        text={'Your Profile'}
                        url={"https://adc.arm.gov/armuserreg/#/login"}
                        {...baseTileItemProps}
                    />
                    <ListItem
                        text={'Logout'}
                        url={"/logout"}
                        {...baseTileItemProps}
                    />
                </ListItem>
                <ListItem
                    text={"Report Bug"}
                    iconClass={"fas fa-bug"}
                    url={null}
                    onClick={toggleReportBugModal}
                    {...baseTileItemProps}
                />
                <ListItem
                    text={"Leave Feedback"}
                    iconClass={"fas fa-comment"}
                    url={null}
                    onClick={toggleFeedbackModal}
                    {...baseTileItemProps}
                />
            </Flexbox>
        )
    }


    const menus = [];
    props.menus.forEach(menu => {
        menus.push(renderMenu(menu));
    });

    const sidebarMenuClassStyles = css`margin-bottom: 250px;`;
    const sidebarContainerStyles = props.isMobileOrTablet ? mobileContainerStyles : containerStyles;

    return (
        <div className={`sidebar-container ${sidebarContainerStyles}`} onClick={onContainClick}>
            <Flexbox id={props.id} className={`sidebar ${sidebarStyles} ${props.isMobileOrTablet && mobileSidebarStyles} ${isTablet && tabletSidebarStyles}`} flexDirection={'column'} flexGrow={1} style={{minHeight: '100%'}}>
                {!props.isMobileOrTablet && <Logo/>}
                <div className={`sidebar-menu-container`}>
                    {menus}
                </div>
                {props.isMobileOrTablet && renderMobileFooter()}
            </Flexbox>
        </div>
    );    
}

Sidebar.propTypes = {
    id: PropTypes.string,
    tiles: PropTypes.array,
    pinned: PropTypes.array,
    menus: PropTypes.array,
    userData: PropTypes.object,
    isMobileOrTablet: PropTypes.bool,
    toggleSidebar: PropTypes.func,
    toggleModal: PropTypes.func
};

export default Sidebar;