import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';

import Flexbox from '@app/components/Flexbox';
import Icon from '@app/components/Icon';
import IconLabel from '@app/components/IconLabel';

const listContainerStyles = css`
    width:100%;
`;

const listItemStyles = css`
    margin-bottom: 5px;
    line-height: 1.2;
`;

const mobileListItemStyles = css`
    font-size: 1.2em;
`;

const mobileListItemPadding = css`
    padding: 13px 0;
`;

const mobileIconLabelStyles = css`
    width: 100%;
    .text {
        width: 100%;
    }
    .icon-container {
        padding: 13px 7px 13px 7px;
    }
`;

const childrenStyles = css`
    padding-left: 20px;
`;

const linkStyles = css`
    color: #fff;
    &:hover {
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
    }
`;

const collapseTextStyles = css`
    margin-right:10px;
`;

const collapsedCaretStyles = css`
    display: inline-block;  
`;

const expandedCaretStyles = css`
    display: inline-block;
    transform: rotate(90deg);
`;

export default class ListItem extends React.Component {
    static propTypes = {
        iconClass: PropTypes.string,
        icon: PropTypes.string, // icon type
        solid: PropTypes.bool, // outline vs solid filled version of icon
        text: PropTypes.string,
        children: PropTypes.array,
        tileProps: PropTypes.object, // if link is tile item and no url or onclick is defined, will use tile props to render about modal
        url: PropTypes.string,
        onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        isMobileOrTablet: PropTypes.bool,
        toggleModal: PropTypes.func,
        toggleSidebar: PropTypes.func
    };

    static defaultProps = {
        solid: false
    };

    state = { childrenVisible: false };

    toggleAboutModal = () => {
        this.props.toggleSidebar();
        this.props.toggleModal(this.props.tileProps.key);
    };

    toggleChildren = () => {
        const currentState = this.state.childrenVisible;
        this.setState({
            childrenVisible: !currentState,
        });
    }

    onClick = () => {
        this.props.onClick();
        this.props.toggleSidebar();
    }

    renderText = () => {
        let text = this.props.text;
    
        const textStyles = this.props.isMobileOrTablet ? mobileListItemPadding : null;
        if (this.props.url) {
            const m = this.props.url.match(/^(?:https?:\/\/i\.arm\.gov)?\/([^\/]+)/);
            if (m === null || m[1] === 'content') {
                const linkProps = {target: "_blank"};
                text = (
                    <a href={this.props.url} className={`${linkStyles} ${textStyles}`} {...linkProps}>{this.props.text}</a>
                );
            } else if (m[1] === 'logout') {
                text = (
                    <a href={this.props.url} className={`${linkStyles} ${textStyles}`}>{this.props.text}</a>
                );
            } else {
                text = (
                    <Link to={`/${m[1]}`} className={`${linkStyles} ${textStyles}`} onClick={this.props.toggleSidebar}>
                        {this.props.text}
                    </Link>
                )
            }
        } else if (this.props.onClick) {
            text = (
                <span className={linkStyles} onClick={this.onClick}>{this.props.text}</span>
            )
        } else if (this.props.tileProps) {
            text = (
                <span className={`${textStyles}`}>
                    <span className={linkStyles} onClick={this.toggleAboutModal}>{this.props.text}</span>
                </span>
            )
        } else if (this.props.isMobileOrTablet) {
            // in mobile view text only list items are headings for collapsable list
            const caretStyles = this.state.childrenVisible ? expandedCaretStyles : collapsedCaretStyles;
            text = (
                <span onClick={this.toggleChildren} className={`${textStyles}`}>
                    <span className={collapseTextStyles}>
                    {text}
                    </span>
                    <span className={caretStyles}>
                        <Icon icon="angle-right" solid/>
                    </span>
                </span>
            );
        }

        return text;
    };

    render() {
        const iconProps = {iconClass: this.props.iconClass, icon: this.props.icon, solid: this.props.solid};
        const mobileChildrenStyles = !this.state.childrenVisible ? css`display:none !important;` : null;
        return (
            <div className={`list-item-container ${listContainerStyles}`}>
                <Flexbox className={`list-item ${listItemStyles} ${this.props.isMobileOrTablet && mobileListItemStyles}`} flexDirection={'row'}>
                    <IconLabel
                        iconComponent={
                            <Icon
                                {...iconProps}
                            />
                        }
                        textComponent={this.renderText()}
                        className={`${this.props.isMobileOrTablet && mobileIconLabelStyles}`}
                    />
                </Flexbox>
                {this.props.children &&
                    <Flexbox className={`sub-items-container ${childrenStyles} ${this.props.isMobileOrTablet && mobileChildrenStyles}`} flexDirection={'column'}>
                        {this.props.children}
                    </Flexbox>
                }
            </div>
        )
    }
}