import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from '@app/components/Flexbox';
import { css, cx } from '@emotion/css';
import { Menu, Dropdown } from 'antd';
import { useMediaQuery } from 'react-responsive';

import Icon from '@app/components/Icon';
import IconLabel from '@app/components/IconLabel';

import { colorBlue, colorGrey, colorBorderGrey, colorLightGrey, textColor } from '@app/styles/base';

const tileStyles = css`
    display: flex;
    flex-direction: column;
    width: 200px;
    min-height: 120px;
    border: 1px solid ${colorBlue};
    margin-left: 20px;
    margin-bottom: 20px;
    padding:0 3px 17px 3px;
    color: ${textColor};

    transition: background-color 0.3s;
    position: relative;
    &:hover {
        cursor: pointer;
        background-color: ${colorBlue};
        color: #fff;
        .icon, .icon a, .icon-container {
            color: #fff;
        }
    }
    > a {
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
    }
`;

const mobileTileStyles = css`
    width: calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin: 10px !important;
`;

let iconStyles = css`
    color: ${colorBlue};
    z-index: 1;
    font-size: 1.2em;
    &:hover {
        cursor: pointer;
    }
    > span, > a {
        padding: 2px 4px;
    }
`;
const codeStyles = css`
    font-family: Oswald;
    text-transform: uppercase;
    padding: 0 0 10px 0;
    font-size: 1.7em;
    margin-top: -3px;
    .icon-container {
        color: ${colorLightGrey};
    }
`;
const prefixStyles = css`
    color: ${colorGrey};
    text-transform: uppercase;
    padding-right: .3em;
`;
const titleStyles = css`
    text-align: center;
    line-height: 1.2;
`;
const mobileTitleStyles = css`
    font-size: 1.5rem;
`;
const menuStyles = css`
    minWidth: 150px;
`;

const TileItem = (props) => {
    const isMobile = useMediaQuery({ maxWidth: 480 });

    const isPinned = () => {
        return Object.values(props.pinned).indexOf(props.tile.key) > -1;
    };

    const togglePinned = (e) => {
        e.stopPropagation();
        let pinned = Object.values(props.pinned);
        if (isPinned()) {
            pinned.splice(pinned.indexOf(props.tile.key), 1);
        } else {
            pinned.push(props.tile.key);
        }

        props.updatePinned(pinned);
    };

    const onTileClick = (e) => {
        props.tile.onClick();
    };

    const onDropdownClicked = (e) => {
        e.stopPropagation();
    };

    const onAboutClick = (e) => {
        if (e.domEvent) {
            e.domEvent.stopPropagation();
        }
        props.toggleModal(props.tile.key);
    };

    const onCancel = (e) => {
        e.stopPropagation();
        props.toggleModal(props.tile.key);
    };

    const getDropdownMenu = () => {
        let menuItems = [];
        if (props.tile.dropdownItems.length > 0) {
            props.tile.dropdownItems.forEach(item => {
                menuItems.push(
                    <Menu.Item key={item.label}>
                        <a href={item.url} target="_blank">{item.label}</a>
                    </Menu.Item>
                )
            });

            menuItems.push(<Menu.Divider key={`${props.tile.key}-divider`}/>);
        }

        menuItems.push(
            <Menu.Item key={'info'} onClick={onAboutClick}>
                <IconLabel
                    iconComponent={
                        <Icon
                            icon="info-circle"
                            solid
                        />
                    }
                    textComponent={'About'}
                    iconAlignCenter
                />
            </Menu.Item>
        );

        return (
            <Menu className={menuStyles}>
                {menuItems}
            </Menu>
        );
    };

    const renderTileContent = () => {
        let filteredIconStyles = null;
        if (props.tile.filtered) {
            filteredIconStyles = css`color:${colorLightGrey}`;
        }
        return (
            <React.Fragment>
                <Flexbox flexDirection={'row'} justifyContent={'space-between'}>
                    <Flexbox className={`icon ${iconStyles} ${filteredIconStyles}`} flexDirection={'column'} justifyContent={'center'}>
                        <span onClick={togglePinned}>
                            <Icon
                                icon="pushpin"
                                solid={isPinned()}
                            />
                        </span>
                    </Flexbox>
                    {!props.isMobileOrTablet &&
                    <Flexbox className={`icon ${iconStyles} ${filteredIconStyles}`}>
                        <Dropdown overlay={getDropdownMenu()} trigger={['click']} onClick={onDropdownClicked}>
                            <a className="ant-dropdown-link" href="#">
                                <Icon
                                    icon="caret-down"
                                    solid
                                />
                            </a>
                        </Dropdown>
                    </Flexbox>
                    }
                </Flexbox>
                <Flexbox className={codeStyles} flexDirection={'row'} justifyContent={'center'}>
                    <IconLabel
                        iconComponent={renderExternalLinkIcon()}
                        textComponent={renderHeading()}
                        iconAfter
                        iconAlignCenter
                        className={css`svg { font-size: .7em; }`}
                    />
                </Flexbox>
                <Flexbox className={`${titleStyles} ${props.isMobileOrTablet && mobileTitleStyles}`} justifyContent={'center'}>
                    {props.tile.title}
                </Flexbox>
            </React.Fragment>
        )
    };

    const renderHeading = () => {
        return (
            <span>
                <span className={prefixStyles}>
                    {props.tile.prefix}
                 </span>
                {props.tile.code}
            </span>
        );
    };

    const renderExternalLinkIcon = () => {
        let icon = null;

        // don't display icon because action on mobile is always to open about modal
        if (!props.isMobileOrTablet && props.tile.url) {
            icon = (
                <Icon
                    icon="external-link-alt"
                    solid
                />
            );
        }

        return icon;
    };

    let filterStyle = '';
        if (props.tile.filtered) {
            filterStyle = css`
                background-color: ${colorBorderGrey};
                border-color: ${colorLightGrey};
                color: ${colorLightGrey};
                .anticon {
                    color: ${colorLightGrey};
                }
                &:hover {
                    background-color: ${colorBorderGrey};
                    border-color: ${colorLightGrey};
                    color: ${colorLightGrey};
                    .anticon {
                        color: ${colorLightGrey};
                    }
                }
            `;
        }

        // default action is to open the about modal for a tile
        let tile = (
            <div onClick={onAboutClick} className={`tile-container ${filterStyle} ${tileStyles} ${isMobile && mobileTileStyles}`}>
                {renderTileContent()}
            </div>
        );

        if (!props.isMobileOrTablet && props.tile.onClick) {
            tile = (
                <div onClick={onTileClick} className={`tile-container ${filterStyle} ${tileStyles} ${isMobile && mobileTileStyles}`}>
                    {renderTileContent()}
                </div>
            );
        } else if (!props.isMobileOrTablet && props.tile.url !== "") {
            tile = (
                <div className={`tile-container ${filterStyle} ${tileStyles} ${isMobile && mobileTileStyles}`}>
                    <a href={props.tile.url} target="_blank" />
                    {renderTileContent()}
                </div>
            );
        }

    return tile;
}

TileItem.propTypes = {
    tile: PropTypes.object,
    pinned: PropTypes.object,
    updatePinned: PropTypes.func,
    isMobileOrTablet: PropTypes.bool,
    toggleModal: PropTypes.func
};

export default TileItem;
