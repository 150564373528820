import styled from '@emotion/styled'
import { Box } from 'rebass'

const TitleBar = styled(Box)`
    position: sticky;
    top: 0;
    z-index: 100;
    background: #eee;
    padding: 10px;
    border-radius: 3px;
    & .title {
        font-family: Oswald;
        margin: 0;
        padding: 0;
        font-size: 28px;
        text-transform: uppercase;
        font-weight: 100;
        color: #aaa;
    }
`

export default TitleBar
