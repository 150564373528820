import React from 'react';

const Flexbox = ({ children, ...rest }) => {
    let style = { ...(rest['style'] || {}), ...{
        display: 'flex',
    }}
    delete rest['style']
    let props = {}
    for (const key in rest) {
        switch (key) {
            case 'alignContent':
            case 'alignItems':
            case 'alignSelf':
            case 'justifyItems':
            case 'justifyContent':
            case 'flex':
            case 'flexBasis':
            case 'flexDirection':
            case 'flexGrow':
            case 'flexShrink':
            case 'flexWrap':
            case 'justifySelf':
            case 'order':
                style[key] = rest[key]
                break
            default:
                props[key] = rest[key]
                break
        }
    }
    return (
        <div style={style} {...props}>
            {children}
        </div>
    )
}

export default Flexbox
