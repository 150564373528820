import React from 'react';
import Flexbox from '@app/components/Flexbox';
import { css } from '@emotion/css';
import { Input, Button, Spin, Popover, Tooltip } from 'antd';
import $ from 'jquery';
import Icon from '@app/components/Icon';
import { colorBorderGrey } from '@app/styles/base';

const apiBaseUrl = process.env.REACT_APP_API_URL;

const { Search } = Input;

const bodyContainerStyle = css`
    width: 100%;
`;
const resultStyle = css`
    border: 1px solid ${colorBorderGrey};
    border-radius: 3px;
    margin: 6px 0;
    padding: 8px 15px;
    .ant-spin-dot-item {
        background-color: white !important;
    }
`;
const searchStyles = css`
    width: 100%;
`;
const spinStyles = css`
    margin-top: 15px;
`;
const popoverStyle = css`
    width: 250px;
    line-break: auto;
    word-wrap: break-word;
`;
const inputStyles = css`
    cursor: default !important;
    background: #fff !important;
    color: black !important;
    border-left: none;
    border-right: none;
    border-radius: 0;
`;


export default class MailingListModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            results: null,
            searching: true,
            copiedList: null,
            copiedMessage: null,
            searchText: ''
        }
    }

    componentDidMount() {
        $.get({
            url: `${apiBaseUrl}/db/people/mailing-list`,
            success: (function (response) {
                let results = [];
                if (response.status === 200) {
                    results = response.result;
                }
                this.setState({ results, searching: false });
            }).bind(this),
            error: (function(err) {
                 console.log('failed to fetch mailing list information');
                 console.log(err);
                this.setState({ results: [], searching: false });
            }).bind(this)
        });
    }

    onSearch = (value) => {
        this.setState({ searchText: value });
    };

    getFilteredResults = () => {
        return this.state.results.filter(list => {
            const searchValue = this.state.searchText.toLowerCase();
            if (searchValue === '') {
                return true;
            }
            const idValue = list.id !== null ? list.id.toLowerCase() : '';
            const descriptionValue = list.description !== null ? list.description.toLowerCase() : '';

            return idValue.indexOf(searchValue) > -1 || descriptionValue.indexOf(searchValue) > -1;
        });
    };

    onCopyClick = (e) => {
        const listName = e.currentTarget.dataset.name;

        //create hidden text area to select and copy
        var target = document.createElement("textarea");
        target.style.position = "absolute";
        target.style.left = "-9999px";
        target.style.top = "0";
        target.id = listName;
        document.body.appendChild(target);
        target.textContent = `${listName}@arm.gov`;
        target.focus();
        target.setSelectionRange(0, target.value.length);

        try {
            const succeed = document.execCommand("copy");
            this.setState({ copiedList: listName, copiedMessage: 'Copied!' });
        } catch(e) {
            this.setState({ copiedList: listName, copiedMessage: 'Failed to copy' });
        }

        setTimeout(() => {
            this.setState({ copiedList: null});
        }, 1500);
    };

    renderResults = () => {
        let results = [];

        if (this.state.results !== null && !this.state.searching) {
            this.getFilteredResults().forEach(list => {
                if (list.members !== null && !Array.isArray(list.members)) {
                    list.members = [list.members];
                }

                if (list.members.length > 0) {
                    const membersContent = list.members.map(member => {
                        let name = member.name_last;
                        if (member.name_first !== null) {
                            name = `${member.name_first.substring(0, 1)} ${name}`;
                        }
                        return name
                    }).join(', ');

                    results.push(
                        <Flexbox key={list.id} flexGrow={1} flexDirection={'column'} className={resultStyle}>
                            <Flexbox flexDirection={'row'} flexGrow={1} className={css`margin-bottom: 5px;`}>
                                <strong>{list.description}</strong>
                            </Flexbox>
                            <Flexbox flexDirection={'row'} flexGrow={1}>
                                <Popover
                                    content={this.state.copiedMessage}
                                    trigger="click"
                                    visible={this.state.copiedList === list.id}
                                    placement="bottom"
                                >
                                    <Button onClick={this.onCopyClick} data-name={list.id}>
                                        <Icon icon={'clipboard'}/>
                                    </Button>
                                </Popover>
                                <Input disabled className={inputStyles} value={`${list.id}@arm.gov`}/>
                                <Popover
                                    content={<div className={popoverStyle}>{membersContent}</div>}
                                    title="List Members"
                                    trigger="click"
                                    placement="bottomRight"
                                >
                                    <Tooltip title="Show list members">
                                        <Button  type="primary">
                                            <Icon icon={'users'} solid/>
                                        </Button>
                                    </Tooltip>
                                </Popover>
                            </Flexbox>
                        </Flexbox>
                    )
                }
            });

            if (results.length === 0) {
                results = <span>No results.</span>
            }
        }

        return results;
    };

    render() {
        return(
            <Flexbox flexDirection={'column'} className={bodyContainerStyle}>
                <Flexbox flexGrow={1} className={css`margin-bottom: 10px;`}>
                    <Search
                        placeholder="Search ..."
                        onSearch={this.onSearch}
                        className={searchStyles}
                    />
                </Flexbox>
                {this.state.searching && <Spin className={spinStyles}/>}
                <Flexbox flexDirection={'column'} >
                    {this.renderResults()}
                </Flexbox>
            </Flexbox>
        )
    }
}