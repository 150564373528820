import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from '@app/components/Flexbox';
import { css } from '@emotion/css';

import TileItem from '@app/components/tiles/TileItem';

import { colorBlue, colorBorderGrey } from '@app/styles/base';


const headingStyles = css`
    color: ${colorBlue};
    border-bottom: solid 1px ${colorBorderGrey};
    font-size: 1.2em;
    margin-bottom: 8px;
    font-family: Oswald;
    text-transform: uppercase;
`;

const TileList = (props) => {
    const renderTiles = () => {
        const tiles = [];
        props.tiles.forEach(tile => {
            tiles.push(
                <TileItem
                    key={tile.key}
                    tile={tile}
                    pinned={props.pinned}
                    updatePinned={props.updatePinned}
                    isMobileOrTablet={props.isMobileOrTablet}
                    toggleModal={props.toggleModal}
                />
            )
        });

        return tiles;
    };

    return (
        <Flexbox className={`tiles-list ${css`flex-shrink:0`}`} flexDirection={'column'}>
            <Flexbox className={`heading ${headingStyles}`}>
                {props.heading}
            </Flexbox>
            <Flexbox className={'tiles'} flexWrap={'wrap'}>
                {renderTiles()}
            </Flexbox>
        </Flexbox>
    );
}

TileList.propTypes = {
    heading: PropTypes.string,
    tiles: PropTypes.array,
    pinned: PropTypes.object,
    updatePinned: PropTypes.func,
    isMobileOrTablet: PropTypes.bool,
    toggleModal: PropTypes.func
};

export default TileList;
