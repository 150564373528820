import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from '@app/components/Flexbox';
import { css } from '@emotion/css';


export default class IconLabel extends React.Component {
    static propTypes = {
        iconComponent: PropTypes.object,
        textComponent: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        iconAfter: PropTypes.bool, // if icon should be after the text component
        iconAlignCenter: PropTypes.bool, // if icon should be vertically center aligned
        className: PropTypes.string
    };

    static defaultProps = {
        iconAfter: false,
        iconAlignCenter: false
    };

    renderIcon = () => {
        let iconStyles = css`
            margin-right: .3em;
            min-width: 20px;
        `;

        if (this.props.iconAfter) {
            iconStyles = css`
                margin-left: .3em;
            `;
        }

        const justifyContent = this.props.iconAlignCenter ? 'center' : 'flex-start';

        return (
            <Flexbox
                className={`icon-container ${iconStyles}`}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={justifyContent}
            >
                {this.props.iconComponent}
            </Flexbox>
        );
    };

    render() {
        return (
            <Flexbox className={this.props.className} flexDirection={'row'}>
                {!this.props.iconAfter &&
                this.renderIcon()
                }
                <Flexbox className={'text'} flexDirection={'column'} justifyContent={'center'}>
                    {this.props.textComponent}
                </Flexbox>
                {this.props.iconAfter &&
                this.renderIcon()
                }
            </Flexbox>
        )
    }
}