import React from 'react';
import PropTypes from 'prop-types';
import Flexbox from '@app/components/Flexbox';
import { css, cx } from '@emotion/css';
import DOMPurify from 'dompurify';

import { baseLinkStyles, colorGrey, colorBorderGrey } from '@app/styles/base';

const linkContainerStyles = css`
    margin-top: 20px;
    padding: 20px 5px 5px 5px;
    border-top: 1px solid ${colorBorderGrey};
`;
const linkListStyles = css`
    margin-bottom: 15px;
    > p {
        margin-bottom:0;
    }
    > div {
        padding-left:15px;
    }
`;

const linkStyles = cx(baseLinkStyles, css`
    white-space: nowrap; 
`);
const prefixStyles = css`
    color: ${colorGrey};
    text-transform: uppercase;
`;

export default class AboutModal extends React.Component {
    static propTypes = {
        tile: PropTypes.object,
        onCancel: PropTypes.func
    };

    onTileClick = (e) => {
        this.props.tile.onClick();
    };

    renderLinks = () => {
        const tile = this.props.tile;

        let items = [];
        tile.dropdownItems.forEach(item => {
            items.push(
                <div key={item.label} className={`drop-item ${linkListStyles}`}>
                    <p className={'drop-link'}>
                        <a href={item.url} className={linkStyles} target="_blank">
                            {item.label}
                        </a>
                    </p>
                    <div>
                        {item.description}
                    </div>
                </div>
            )
        });

        if (items.length > 0) {
            return (
                <Flexbox className={`links-container ${linkContainerStyles}`} flexDirection={'column'} flexGrow={1}>
                    {items}
                </Flexbox>
            )
        }
    };

    render() {
        let mainLink = null;
        if (this.props.tile.onClick) {
            mainLink = (
                <div className={`main-link ${css`margin-bottom:8px`}`}>
                <span onClick={this.onTileClick} className={baseLinkStyles}>
                    {this.props.tile.title}
                </span>
                </div>
            );
        } else if (this.props.tile.url !== "") {
            mainLink = (
                <div className={`main-link ${css`margin-bottom:8px`}`}>
                    <a href={this.props.tile.url} className={baseLinkStyles} target="_blank">
                        {this.props.tile.title}
                    </a>
                </div>
            );
        }

        return (
            <div className={`about-body`}>
                <div className={'description'}>
                    {mainLink}
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.tile.description) }} />
                </div>
                <div>
                    {this.renderLinks()}
                </div>
            </div>
        );
    }
}