import React from 'react'
import ReactMarkdown from 'react-markdown'
import { css } from '@emotion/css'
import $ from 'jquery'
import { Flex, Box } from 'rebass'
import { Button, Input, Spin, Tooltip } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useQueryParam, withDefault, BooleanParam, StringParam, NumberParam } from 'use-query-params'
import { useMediaQuery } from 'react-responsive';

import Icon from '@app/components/Icon'
import TitleBar from '@app/components/TitleBar'
import {
    colorBlue,
    colorBlueBright,
    colorBlueLight,
    colorBlueLightest,
    colorGreen,
    colorGreenDark,
} from '@app/styles/base'

const styles = css`

    #print-title {
        display: none;
    }

    #overview h5 {
        color: #999;
    }

    @media print {
        #tree {
            font-size: 10px;
        }

        #nav {
            display: none !important;
        }

        #overview {
            display: none !important;
        }

        #print-title {
            display: block !important;
        }

        .node-id {
            background-color: transparent !important;
            align-items: flex-start;
            border-right: 1px solid #ccc;
        }

        .node-selected {
            background: transparent;
        }

        ol {
            border: none !important;
            padding-left: 0 !important;
        }

        a::after {
            content: "" !important;
        }

        .layer-buttons {
            display: none;
        }
    }
`

const treeStyles = css`
    & {
        padding: 0 20px 20px 20px;
        max-width: 1100px;
    }

    & * {
        line-height: 1.3;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 22px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 18px;
    }
    h6 {
        font-size: 16px;
    }

    ol {
        list-style: none;
        margin: 0;
        padding: 5px 0 0 5px;
        border: 5px solid ${colorBlue};
        border-width: 0 0 0 5px;
    }

    li {
        margin: 0 0 5px 0;
        padding: 0;
    }

    & > ol {
        padding-left: none;
    }

    .node-wrapper {
    }

    .node-wrapper.node-selected {
        background-color: #eee;
        border-radius: 0 10px 10px 0;
    }

    .node-heading {
        font-family: Oswald;
        font-weight: 300;
        text-transform: uppercase;
        padding: 5px 0;
    }

    .node-name {
        border-bottom: 1px solid #ccc;
    }

    .node-people {
        padding: 10px 0 0 0;
    }

    .node-id {
        flex-shrink: 2;
        background-color: ${colorBlue};
        color: ${colorBlueLight};
        min-width: 100px;
        text-align: center;
        border-radius: 0 10px 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .level-1 + ol {
        border-color: ${colorGreenDark};
    }
    .node-id.level-1 {
        background-color: ${colorGreenDark};
        color: #fff;
    }
    .level-2 + ol {
        border-color: ${colorGreen};
    }
    .node-id.level-2 {
        background-color: ${colorGreen};
        color: #fff;
    }
    .level-3 + ol {
        border-color: ${colorBlue};
    }
    .node-id.level-3 {
        background-color: ${colorBlue};
    }
    .level-4 + ol {
        border-color: ${colorBlueBright};
    }
    .node-id.level-4 {
        background-color: ${colorBlueBright};
        color: ${colorBlue};
    }
    .level-5 + ol {
        border-color: ${colorBlueLight};
    }
    .node-id.level-5 {
        background-color: ${colorBlueLight};
        color: ${colorBlue};
    }
    .node-id.level-6 {
        background-color: ${colorBlueLightest};
        color: ${colorBlue};
    }

    .node > .node-heading {
        width: 100%;
    }

    .node {
        flex-grow: 5;
        padding: 0 20px 0 10px;
    }

    .node-person {
        font-size: 120%;
        margin-bottom: 0.25rem;
    }

    .node-person .sep {
        color: #ccc;
        padding: 0 5px;
    }

    .person-org, .person-acting {
        color: #aaa;
    }

    .node-desc {
        margin: 0.5rem 0 0 0;
        white-space: pre-wrap;
    }

    .tn-enter,
    .tn-exit-active {
        opacity: 0;
        max-height: 0;
    }
    .tn-exit,
    .tn-enter-active {
        opacity: 1;
        max-height: 5000px;
        overflow: hidden;
    }
    .tn-enter-active,
    .tn-exit-active {
        transition-timing-function: ease-in;
        transition:
            opacity 300ms,
            max-height 300ms;
    }

    .nchildren {
        margin-left: 3px;
        font-size: 70%;
        opacity: 0.6;
    }
`

const mobileTreeStyles = css`
    h1 {
        font-size: 20px;
    }
    h2 {
        font-size: 18px;
    }
    h3 {
        font-size: 16px;
    }
    h4 {
        font-size: 14px;
    }
    h5 {
        font-size: 14px;
    }
    h6 {
        font-size: 14px;
    }

    .nchildren {
        margin-left: 0;
    }
    .node-id {
        min-width: 45px;
    }
    .node-person {
        font-size: 110%;
        margin-bottom: 1.5rem;
    }
    .node-desc {
        word-wrap: break-word;
    }
`;

const buildNode = (
    role,
    showNames,
    showDescs,
    showChildCounts,
    selectedRole,
    onSelect,
    nLayers,
    onChangeLayers,
    filteredNodes,
    isMobileOrTablet,
    isMobile,
    level=1
) => {
    const isSelected = role.id === selectedRole?.id
    return (
        <React.Fragment key={role.org_id}>
            {filteredNodes.indexOf(role.id) < 0 && (
            <div className={`node-wrapper level-${level}${isSelected ? ' node-selected' : ''}`} style={{width: '100%'}}>
                <Flex width='100%'>
                    <NodeHeading level={level} className={`level-${level} node-heading node-id`}>
                        <Flex flexDirection={isMobileOrTablet ? 'column' : 'row'}>
                            <Flex>
                                {role.org_id}
                            </Flex>
                        {showChildCounts && (
                            <Flex className="nchildren" flexDirection={'column'} justifyContent={'center'}>
                                +{role.subroles.length}
                            </Flex>
                        )}
                        </Flex>
                    </NodeHeading>
                    <Flex className="node" flexDirection="column">
                        <NodeHeading level={level} className={`level-${level} node-heading node-name`}>
                            <a href="" onClick={e => { e.preventDefault(); onSelect(role) }}>
                                <Flex flexGrow={2}>
                                    {role.label}
                                    {isSelected && (
                                        <Flex justifyContent="space-between" ml={1} alignItems="center" flexGrow={2}>
                                            {/* hide show level of depth feature on mobile for more simple view */}
                                            <Icon icon="check-circle" solid />
                                            {!isMobileOrTablet && <Flex className="layer-buttons" flexGrow={2} justifyContent="flex-end">
                                            {[...Array(role.layers).keys()].map(i => (
                                                <Tooltip key={i} title={`Shows ${i+1} Level(s) of Depth`}>
                                                    <Button
                                                        shape="circle"
                                                        style={{marginLeft: '3px'}}
                                                        type={nLayers === i+1 ? 'primary' : 'default'}
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            onChangeLayers(i+1)
                                                        }}
                                                    >
                                                        {i + 1}
                                                    </Button>
                                                </Tooltip>
                                            ))}
                                            </Flex>
                                            }
                                        </Flex>
                                    )}
                                </Flex>
                            </a>
                        </NodeHeading>
                        <Flex flexDirection="column">
                            <Flex flexDirection="column" className={`${showNames ? 'node-people' : ''}`}>
                                <TransitionGroup component={null}>
                                {showNames && role.people.map(p => (
                                    <CSSTransition key={p.person_id} classNames="tn" timeout={300}>
                                        {isMobile ?
                                        <Flex flexDirection={'column'} className="node-person">
                                            <span className="person-name">
                                                {p.is_acting && (
                                                    <span className="person-acting">(Acting) </span>
                                                )}
                                                {p.name_first} {p.name_last}
                                            </span>
                                            <span className="person-email"><a href={`mailto:${p.email}`}>{p.email.toLowerCase()}</a></span>
                                            <span className="person-org">{p.org}</span>
                                        </Flex>
                                        :
                                        <Box className="node-person">
                                            {p.is_acting && (
                                                <span className="person-acting">(Acting) </span>
                                            )}
                                            <span className="person-name">{p.name_first} {p.name_last}</span>
                                            <span className="sep">//</span>
                                            <span className="person-email"><a href={`mailto:${p.email}`}>{p.email.toLowerCase()}</a></span>
                                            <span className="sep">//</span>
                                            <span className="person-org">{p.org}</span>
                                        </Box>
                                        }
                                    </CSSTransition>
                                ))}
                                </TransitionGroup>
                            </Flex>
                            <TransitionGroup component={null}>
                            {showDescs && (
                                <CSSTransition classNames="tn" timeout={300}>
                                    <Box className="node-desc">
                                        <ReactMarkdown>{role.desc}</ReactMarkdown>
                                    </Box>
                                </CSSTransition>
                            )}
                            </TransitionGroup>
                        </Flex>
                    </Flex>
                </Flex>
            </div>
            )}
            {role.subroles.length > 0 && (
                selectedRole === null || (
                    level <= selectedRole.parents.length + nLayers
                )
            ) && (
                <TransitionGroup component="ol" className={`level-${level}-wrapper`}>
                    {role.subroles
                        .filter(subrole =>
                            selectedRole === null ||
                            level > selectedRole.parents.length ||
                            selectedRole.parents.indexOf(subrole.id) >= 0 ||
                            subrole.id === selectedRole?.id
                        )
                        .map(
                            subrole => (
                                <CSSTransition key={subrole.id} timeout={300} classNames="tn">
                                    <li key={subrole.id} className={`${subrole.subroles.length ? "branch" : "leaf"}`}>
                                        {buildNode(
                                            subrole,
                                            showNames,
                                            showDescs,
                                            showChildCounts,
                                            selectedRole,
                                            onSelect,
                                            nLayers,
                                            onChangeLayers,
                                            filteredNodes,
                                            isMobileOrTablet,
                                            isMobile,
                                            level+1
                                        )}
                                    </li>
                                </CSSTransition>
                            )
                        )
                    }
                </TransitionGroup>
            )}
        </React.Fragment>
    )
}

const NodeHeading = ({ level, children, ...props }) => {
    return React.createElement(`h${level}`, props, children)
}

const processNode = (node, byId={}, parents=[]) => {
    node.parents = parents
    node.layers = 0
    let layer = parents.length
    for (const p of parents) {
        const parent = byId[p]
        if (parent.layers < layer) {
            parent.layers = layer
        }
        layer--
    }
    parents = parents.slice()
    parents.push(node.id)
    byId[node.id] = node
    node.subroles.forEach(n => {
        processNode(n, byId, parents)
    })
    return {node, byId}
}

const genFilteredNodes = (nodeById, filterText) => {
    const txt = filterText.toLowerCase().split(/\s+/)
    if (!txt.length || !txt[0]) {
        return null
    }
    let filter = []
    for (const id in nodeById) {
        const node = nodeById[id]
        const m = txt.filter(t => [
            node.name ?? "",
            node.label ?? "",
            node.desc ?? "",
            ...node.people.map(p => [
                p.name_first ?? "",
                p.name_last ?? "",
                p.email ?? "",
                p.org ?? "",
            ].join("")),
        ].join("").toLowerCase().indexOf(t) >= 0)
        if (!m.length) {
            filter.push(id)
        }
    }
    return filter
}

const Organization = ({ apiUrl, isMobileOrTablet }) => {
    const [loading, setLoading] = React.useState(true)
    const [rootNode, setRootNode] = React.useState(null)
    const [nodeById, setNodeById] = React.useState({})
    const [showNames, setShowNames] = useQueryParam('name', withDefault(BooleanParam, true))
    const [showDescs, setShowDescs] = useQueryParam('desc', withDefault(BooleanParam, false))
    const [showChildCount, setShowChildCount] = useQueryParam('cnt', withDefault(BooleanParam, true))
    const [focusNodeId, setFocusNodeId] = useQueryParam('role', withDefault(StringParam, null))
    const [focusLayers, setFocusLayers] = useQueryParam('n', withDefault(NumberParam, 1))
    const [filteredText, setFilteredText] = useQueryParam('f', withDefault(StringParam, ""))
    const [filteredNodes, setFilteredNodes] = React.useState([])

    // Making this uncontrolled in order to avoid a render on each key press,
    // which isn't snappy enough
    const filterInput = React.useRef(null)
    const isMobile = useMediaQuery({ maxWidth: 480 });

    React.useEffect(() => {
        if (rootNode !== null) {
            return
        }
        $.get({
            url: `${apiUrl}/db/people/organization`,
            success: (function (response) {
                if (response.result === undefined) {
                    console.log(arguments)
                    setLoading(false)
                    return
                }
                let {node, byId} = processNode(response.result)
                setNodeById(byId)
                setRootNode(node)
                setLoading(false)
                if (filterInput.current) {
                    filterInput.current.state.value = filteredText
                }
            }).bind(this),
            error: (function(err) {
                console.error(err)
            }).bind(this),
            xhrFields: {
                withCredentials: true,
            },
            crossDomain: true,
        })
    }, [rootNode])

    React.useEffect(() => {
        const filter = genFilteredNodes(nodeById, filteredText)
        if (filter === null) {
            setFilteredNodes([])
            return
        }
        setFilteredNodes(filter)
        setFocusNodeId(null)
    }, [filteredText, rootNode])

    if (loading) {
        return (
            <Flex flexDirection="column" flex={1} justifyContent="center" alignItems="center">
                <Spin/>
            </Flex>
        );
    }

    const printButtonComponent = (
        <Button onClick={() => window.print()} type="text" icon={<PrinterOutlined />} style={{marginRight: '5px'}} />
    );

    const filterInputComponent = (
        <Input
            ref={filterInput}
            placeholder="Search / Filter ..."
            allowClear={true}
            onChange={e => {
                if (e.target.value === "") {
                    setFilteredText("")
                }
            }}
            onPressEnter={e => setFilteredText(e.target.value)}
        />
    );

    const displayButtonsComponent = (
        <React.Fragment>
            <Button onClick={() => setShowNames(!showNames)}>
                {showNames ? 'Hide' : 'Show'} People
            </Button>
            <Button onClick={() => setShowDescs(!showDescs)}>
                {showDescs ? 'Hide' : 'Show'} Descriptions
            </Button>
            <Button onClick={() => setShowChildCount(!showChildCount)}>
                {showChildCount ? 'Hide' : 'Show'} Subrole Counts
            </Button>
        </React.Fragment>
    );

    return (
        <Box className={`${styles}`}>
            <TitleBar id="nav" style={{position: 'sticky', top: 0, zIndex: 100}}>
                <Flex justifyContent="space-between" flexWrap={'wrap'} alignItems="center">
                    {isMobileOrTablet ?
                    <React.Fragment>
                        <Flex justifyContent="space-between" flexGrow={1}>
                            <h1 id="title" className="title">
                                ARM Organization
                            </h1>
                            {printButtonComponent}
                        </Flex>
                        <Flex flexWrap={'wrap'}>
                            {filterInputComponent}
                            {displayButtonsComponent}
                        </Flex>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <h1 id="title" className="title">
                            ARM Organization
                        </h1>
                        <Flex>
                            {printButtonComponent}
                            {filterInputComponent}
                            {displayButtonsComponent}
                        </Flex>
                    </React.Fragment>
                    }
                </Flex>
            </TitleBar>
            <h1 id="print-title" className="title">
                ARM Organization Plan
            </h1>
            <Box p={3} id="overview">
                <em>
                    <h5>
                        <p>
                            For a more visual, high-level representation of ARM's organization, please refer to&nbsp;
                            <a href="https://arm.gov/connect-with-arm/organization" target="_blank">
                                this page on arm.gov
                            </a>.
                        </p>
                        <p>
                            By clicking a role title below, the structure will adjust to show only that role's parents
                            and children. Clicking the role title again will reset the view back to the entire
                            organization.
                        </p>
                    </h5>
                </em>
            </Box>
            <Box id="tree">
            {rootNode &&
                <Box className={`${treeStyles} ${isMobileOrTablet && mobileTreeStyles}`}>
                    {buildNode(
                        rootNode,
                        showNames,
                        showDescs,
                        showChildCount,
                        focusNodeId !== null && nodeById.hasOwnProperty(focusNodeId)
                            ? nodeById[focusNodeId]
                            : null,
                        role => {
                            if (filterInput.current) {
                                filterInput.current.state.value = ""
                            }
                            const id = focusNodeId === role.id ? null : role.id
                            setFocusLayers(1)
                            setFocusNodeId(id)
                            setFilteredText("")
                            setFilteredNodes([])
                            if (id !== null) {
                                window.scrollTo({top: 0, behavior: 'smooth'})
                            }
                        },
                        focusLayers,
                        setFocusLayers,
                        filteredNodes,
                        isMobileOrTablet,
                        isMobile
                    )}
                </Box>
            }
            </Box>
        </Box>
    )
}
export default Organization