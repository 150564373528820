import React from 'react';
import { css } from '@emotion/css';
import { Modal, Button, Input, Select, Spin, Menu, Dropdown, Radio } from 'antd';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { useMediaQuery } from 'react-responsive';
import $ from 'jquery';

import Flexbox from '@app/components/Flexbox';
import Sidebar from '@app/components/sidebar/Sidebar';
import TileList from '@app/components/tiles/TileList';
import MobileModal from '@app/components/modals/MobileModal';
import PeopleModal from '@app/components/modals/PeopleModal';
import ReportBugModal from '@app/components/modals/ReportBugModal';
import MailingListModal from '@app/components/modals/MailingListModal';
import FeedbackModal from '@app/components/modals/FeedbackModal';
import AboutModal from '@app/components/modals/AboutModal';
import Icon from '@app/components/Icon';
import IconLabel from '@app/components/IconLabel';
import Organization from '@app/components/views/Organization';
import NewsArchive from '@app/components/views/NewsArchive';

import Logo from '@app/components/Logo';
import { baseLinkStyles, colorBlue, colorGrey } from '@app/styles/base';

import 'typeface-open-sans-condensed';
import 'typeface-oswald';
import 'typeface-roboto-condensed';

const { Search } = Input;
const { Option } = Select;

const apiBaseUrl = process.env.REACT_APP_API_URL;
const wpApiBaseUrl = process.env.REACT_APP_WP_API_URL;

const MODAL_PEOPLE = 'people';
const MODAL_LISTS = 'lists';
const MODAL_BUG = 'bug';
const MODAL_FEEDBACK = 'feedback';


const appStyles = css`
    & {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    @media print {
        #sidebar, #header {
            display: none !important;
        }
    }

    .ant-radio-button-wrapper {
        font-weight: normal;
        &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            background-color: ${colorBlue};
            color: #fff;
        }
    }
`

const contentStyles = css`
    padding: 3px 10px;
`;

const headerStyles = css`
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0 0 20px 10px #fff;
    margin-bottom: 20px;
`;

const mobileHeaderStyles = css`
    background-color: ${colorBlue};
    color: #fff;
    font-size: 24px;
    padding: 3px 0;
`;

const mobileHeaderIconStyles =  css`
    margin:5px 10px;
`;

const topRowStyles = css`
    font-size: 1em;
    margin: 3px 8px 8px 0;
`;

const linkContainerStyles = css`
    margin-left: 25px;
`;

const filterStyles = css`
    margin-bottom: 15px;
    .ant-select {
        width: 150px;
    }
    .ant-input-search {
        width: 300px;
    }
`;

const prefixStyles = css`
    color: ${colorGrey};
    text-transform: uppercase;
`;


const App = ({}) => {
    const [sortType, setSortType] = React.useState('alphabetical');
    const [searchText, setSearchText] = React.useState('');
    const [visibleModal, setVisibleModal] = React.useState(null);
    const [showSidebar, setShowSidebar] = React.useState(false);
    const [userData, setUser] = React.useState({ username: null, name: null, email: null});
    const [pinnedData, setPinned] = React.useState([]);
    const [tilesData, setTiles] = React.useState([]);
    const [categoriesData, setCategories] = React.useState({});
    const [menusData, setMenus] = React.useState([]);
    const [loadingUser, setLoadingUser] = React.useState(true);
    const [loadingPinnedCache, setLoadingPinnedCache] = React.useState(true);
    const [loadingTiles, setLoadingTiles] = React.useState(true);
    const [loadingCategories, setLoadingCategories] = React.useState(true);
    const [loadingMenus, setLoadingMenus] = React.useState(true);

    const isMobileOrTablet = useMediaQuery({ maxWidth: 900 });

    React.useEffect(() => {
        fetchUser();
        fetchWPTiles();
        fetchWPCategories();
        fetchWPMenus();
    }, []);

    React.useEffect(() => {
        if (isMobileOrTablet) {
            window.scrollTo(0, 0);
        }
    }, [visibleModal]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [sortType]);

    const fetchUser = () => {
            $.get({
                url: `${apiBaseUrl}/user`,
                success: (function (response) {
                    fetchCachePinned();
                    setUser(response);
                }).bind(this),
                error: (function(err) {
                    login();
                }).bind(this),
                done: (function() {
                    setLoadingUser(false);
                }).bind(this),
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
    };

    const login = () => {
        window.location.replace(`${apiBaseUrl}/sso`);
    }

    const fetchCachePinned = () => {
        $.get({
            url: `${apiBaseUrl}/pinned`,
            success: (function (response) {
                setPinned(response);
            }).bind(this),
            error: (function(err) {
                console.log('failed to fetch user pinned cache');
                console.log(err);

            }).bind(this),
            done: (function() {
                setLoadingPinnedCache(false);
            }).bind(this),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true
        });
    };

    const fetchWPTiles = () => {
        $.get({
            // WP API maxes out at 100, will need to update for multiple and
            // combine calls if we ever get more than 100
            url: `${wpApiBaseUrl}/tiles?per_page=100`,
            success: (function (response) {

                // Map return data to match tile object structure for UI
                const tiles = response.map((wpTile) => {
                    const categories = wpTile['tile-category'] ? wpTile['tile-category'] : [];

                    const dropdownItems = wpTile.acf.menu ? wpTile.acf.menu : [];

                    let onClick = false;
                    if (wpTile.acf.needs_data) {
                        const tileData = JSON.parse(wpTile.acf.data);
                        if (tileData.id) {
                            onClick = () => { toggleModal(tileData.id)};
                        }
                    }
                    return ({
                        key: wpTile.id,
                        prefix: null,
                        code: wpTile.acf.name,
                        title: wpTile.acf.label,
                        url: wpTile.acf.url,
                        description: wpTile.acf.description,
                        categories,
                        pinned: false,
                        dropdownItems,
                        onClick
                    });
                });

                setTiles(tiles);
            }).bind(this),
            error: (function(err) {
                console.log('failed to fetch tile metadata from wordpress');
                console.log(err);

            }).bind(this),
            done: (function() {
                setLoadingTiles(false);
            }).bind(this)
        });
    };

    const fetchWPCategories = () => {
        $.get({
            url: `${wpApiBaseUrl}/tile-category?per_page=100`,
            success: (function (response) {
                const categories = {};
                response.forEach((wpCategory) => {
                    categories[wpCategory.id] = wpCategory.name;
                });
                setCategories(categories);
            }).bind(this),
            error: (function(err) {
                console.log('failed to fetch category metadata from wordpress');
                console.log(err);
            }).bind(this),
            done: (function() {
                setLoadingCategories(false);
            }).bind(this)
        });
    };

    const fetchWPMenus = () => {
        $.get({
            url: `${wpApiBaseUrl}/menus?per_page=100`,
            success: (function (response) {
                const menus = response.sort((a,b) => {
                    return parseInt(a.acf.placement) - parseInt(b.acf.placement);
                });
                setMenus(menus);
            }).bind(this),
            error: (function(err) {
                console.log('failed to fetch menu metadata from wordpress');
                console.log(err);
            }).bind(this),
            done: (function() {
                setLoadingMenus(false);
            }).bind(this)
        });
    };

    const getPinnedTiles = () => {
        const pinned = [];
        tilesData.forEach(tile => {
            if (pinnedData.indexOf(tile.key) > -1) {
                tile.filtered = true;
                pinned.push(tile);
            }
        });

        return pinned;
    };

    const getTileByKey = (key) => {
        let aboutTile = null;
        tilesData.forEach(tile => {
            if (tile.key === key) {
                aboutTile = tile
            }
        })

        return aboutTile;
    }

    const updatePinned = (pinned) => {
        $.post({
            url: `${apiBaseUrl}/pinned`,
            data: JSON.stringify(
                pinned
            ),
            success: (function (response) {
            }).bind(this),
            error: (function(err) {
                console.log('failed to update pinned');
                console.log(err);
            }).bind(this),
            xhrFields: {
                withCredentials: true
            },
            crossDomain: true
        });

        setPinned(pinned);
    };

    const togglePinned = (key) => {
        const isPinned = Object.values(pinnedData).indexOf(key) > -1;
        const pinned = pinnedData;

        if (isPinned) {
            pinned.splice(pinned.indexOf(key), 1);
        } else {
            pinned.push(key);
        }

        updatePinned(pinned);
    }

    const onSearch = (value) => {
        setSearchText(value);
    };

    const onSort = (value) => {
        // select passes string value
        if (typeof value !== 'string') {
            // radio button passes event object
            value = value.target.value;
        }
        setSortType(value);
    };

    const toggleModal = (modalName) => {
        // modal cancel function returns object of modal component data
        // custom modals pass a string for modal name
        // about modals pass an id number for modal name
        const visibleModal = typeof modalName === 'string' || typeof modalName === 'number' ? modalName : null;
        setVisibleModal(visibleModal);
    };

    const togglePeopleModal = () => {
        toggleModal(MODAL_PEOPLE);
    };

    const toggleReportBugModal = () => {
        toggleModal(MODAL_BUG);
    };

    const toggleFeedbackModal = () => {
        toggleModal(MODAL_FEEDBACK);
    };

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    }

    const renderHeader = (withTiles = true, withModal = false) => {
        const userMenu = (
            <Menu>
                <Menu.Item>
                    <a href="https://adc.arm.gov/armuserreg/#/login" target="_blank">Your Profile</a>
                </Menu.Item>
                <Menu.Item>
                    <a href="/logout">Logout</a>
                </Menu.Item>
            </Menu>
        );

        if (isMobileOrTablet) {
            return (
                <Flexbox id="header" className={`header-container ${headerStyles}`} flexDirection="column">
                    <Flexbox className={`${mobileHeaderStyles} ${css`flex-shrink:0`}`} flexDirection="row" justifyContent="space-between">
                        <Flexbox className={`${mobileHeaderIconStyles}`} flexDirection="column" justifyContent="center" onClick={toggleSidebar}>
                            <Icon
                                icon="bars"
                                solid
                            />
                        </Flexbox>
                        <Logo mobile/>
                        <Flexbox className={`${mobileHeaderIconStyles}`} flexDirection="column" justifyContent="center" onClick={togglePeopleModal}>
                            <Icon
                                icon="users"
                                solid
                            />
                        </Flexbox>
                    </Flexbox>
                    {(withTiles && !withModal) &&
                    <Flexbox flexDirection={"column"}>
                        <Search
                            placeholder="Search i.arm.gov..."
                            defaultValue={searchText}
                            onSearch={onSearch}
                            className={css`padding: 10px;`}
                        />
                        <Flexbox flexDirection={"row"} justifyContent={"center"}>
                            <Radio.Group value={sortType} onChange={onSort}>
                                <Radio.Button value="alphabetical">Alphabetical</Radio.Button>
                                <Radio.Button value="categories">Categories</Radio.Button>
                            </Radio.Group>
                        </Flexbox>
                    </Flexbox>
                    }
                </Flexbox>
            )
        }

        return (
            <Flexbox id="header" className={`header-container ${headerStyles} ${contentStyles} ${css`flex-shrink:0`}`} flexDirection={'column'}>
                <Flexbox className={`top-row ${topRowStyles}`} flexDirection={'row'} justifyContent={'flex-end'}>
                    {!withTiles && (
                        <Flexbox flexGrow={1}>
                            <Flexbox className={`link-container ${linkContainerStyles} ${baseLinkStyles}`} flexDirection={'row'} style={{marginLeft: 0}}>
                                <Link to="/">
                                    <IconLabel
                                        iconComponent={
                                            <Icon
                                                icon="home"
                                                solid
                                            />
                                        }
                                        textComponent={'Home'}
                                        iconAlignCenter
                                    />
                                </Link>
                            </Flexbox>
                        </Flexbox>
                    )}
                    <Flexbox className={`link-container ${linkContainerStyles} ${baseLinkStyles}`} flexDirection={'row'} onClick={toggleReportBugModal}>
                        <IconLabel
                            iconComponent={
                                <Icon
                                    icon="bug"
                                    solid
                                />
                            }
                            textComponent={'Report Bug'}
                            iconAlignCenter
                        />
                    </Flexbox>
                    <Flexbox className={`link-container ${linkContainerStyles} ${baseLinkStyles}`} flexDirection={'row'} onClick={toggleFeedbackModal}>
                        <IconLabel
                            iconComponent={
                                <Icon
                                    icon="comment"
                                    solid
                                />
                            }
                            textComponent={'Leave Feedback'}
                            iconAlignCenter
                        />
                    </Flexbox>
                    <Flexbox className={`link-container ${linkContainerStyles}`}>
                        Welcome, {userData.name}
                        <Dropdown overlay={userMenu} trigger={['click']} >
                            <a className={`${css`margin-left:6px;`} ant-dropdown-link`} href="#">
                                <Icon
                                    icon="caret-down"
                                    solid
                                />
                            </a>
                        </Dropdown>
                    </Flexbox>
                </Flexbox>
                {withTiles && (
                    <Flexbox className={`filters-container ${filterStyles}`} flexDirection={'row'} justifyContent={'space-between'}>
                    <Select defaultValue={sortType} onChange={onSort}>
                        <Option value="alphabetical">Alphabetical</Option>
                        <Option value="categories">Categories</Option>
                    </Select>

                    <Flexbox>
                        <Button type="primary" className={css`margin-right:8px;`} onClick={togglePeopleModal}>
                            <IconLabel
                                iconComponent={
                                    <Icon
                                        icon="users"
                                        solid
                                    />
                                }
                                textComponent={'People'}
                                iconAlignCenter
                            />
                        </Button>
                        <Search
                            placeholder="Search i.arm.gov..."
                            onSearch={onSearch}
                        />
                    </Flexbox>
                </Flexbox>
                )}
            </Flexbox>
        );
    };

    const checkObjectForText = (object, filterText, match) => {
        Object.values(object).forEach(value => {
            if (value && typeof(value) === 'string' && value.toLowerCase().indexOf(filterText.toLowerCase()) > -1) {
                match = true;
            } else if (value && typeof(value) === 'object') {
                match = checkObjectForText(value, filterText, match);
            }
        });

        return match;
    };

    const filterTileBySearch = (tiles) => {
        let filteredTiles = tiles;
        if (searchText) {
            filteredTiles = tiles.filter(tile => {
                let match = false;

                match = checkObjectForText(tile, searchText, match);

                return match;
            });
        }

        return filteredTiles;
    };

    const renderTileLists = () => {
        const filteredTiles = filterTileBySearch(tilesData);

        let listsObj = {};
        let lists = [];
        const pinnedTiles = getPinnedTiles();
        let filteredPinned = [];
        pinnedTiles.forEach(pinnedTile => {
            filteredTiles.forEach(tile => {
                if (tile.code === pinnedTile.code && tile.prefix === pinnedTile.prefix) {
                    pinnedTile.filtered = false;
                }
            });
            filteredPinned.push(pinnedTile)
        });

        const sortAlphabetical = function (prop) {
            return function (a,b) {
                return a[prop].localeCompare(b[prop], 'en', {'sensitivity': 'base'});
            };
        };

        if (sortType === 'categories') {
            Object.keys(categoriesData).forEach(id => {
                const key = categoriesData[id];
                const title = key.replace(/-/g, ' ');
                listsObj[id] = { key, title, tiles: []}
            });

            filteredTiles.forEach(item => {
                item.categories.forEach(itemCategory => {
                    listsObj[itemCategory].tiles.push(item);
                })
            });

            Object.keys(listsObj).forEach(key => {
                listsObj[key].tiles.sort(sortAlphabetical('code'));
            });

            lists = Object.values(listsObj).sort(sortAlphabetical('title'));

        } else {
            lists = [{ key: 'all', title: 'All Tools and Links', tiles: filteredTiles.sort(sortAlphabetical('code')) }];
        }

        lists = [{ key: 'pinned', title: 'Pinned', tiles: filteredPinned.sort(sortAlphabetical('code')) }, ...lists];
        const pinnedProp = {};
        pinnedData.forEach(p => {
            pinnedProp[p] = p;
        });

        const listComponents = [];
        lists.forEach(list => {
            if (list.tiles.length > 0) {
                listComponents.push(
                    <TileList
                        key={list.key}
                        heading={list.title}
                        tiles={list.tiles}
                        pinned={pinnedProp}
                        updatePinned={updatePinned}
                        isMobileOrTablet={isMobileOrTablet}
                        toggleModal={toggleModal}
                    />
                );
            }
        });

        return listComponents;
    };

    const renderModal = () => {
        if (visibleModal !== null) {
            const modalProps = {
                title: null,
                footer: null,
                modalBody: null
            };

            switch (visibleModal) {
                case MODAL_PEOPLE:
                    modalProps.title = 'ARM Directory';
                    modalProps.modalBody = <PeopleModal isMobileOrTablet={isMobileOrTablet}/>;
                    modalProps.footer = (
                        <Flexbox>
                            <p>
                                Need to update your profile? <a href="https://adc.arm.gov/armuserreg/#/login" target="_blank"> Manage Account <Icon icon="external-link-alt" solid/></a>
                            </p>
                        </Flexbox>
                    );
                    break;
                case MODAL_LISTS:
                    modalProps.title = 'Email Lists';
                    modalProps.modalBody = <MailingListModal/>;
                    break;
                case MODAL_BUG:
                    modalProps.title = 'Report Bug';
                    modalProps.modalBody = <ReportBugModal email={userData.email} name={userData.name}/>;
                    break;
                case MODAL_FEEDBACK:
                    modalProps.title = 'Leave Feedback';
                    modalProps.modalBody = <FeedbackModal email={userData.email}/>;
                    break;
                default:
                    const aboutTile = getTileByKey(visibleModal);
                    if (aboutTile) {
                        const title = aboutTile.code;
                        if (aboutTile.prefix) {
                            const prefix = <span className={prefixStyles}>{aboutTile.prefix}</span>;
                            title = <span>{prefix} {title}</span>;
                        }

                        modalProps.title = title;
                        modalProps.modalBody = <AboutModal tile={aboutTile} onCancel={toggleModal}/>;
                        modalProps.tileKey = aboutTile.key;
                        modalProps.isPinned = pinnedData.indexOf(aboutTile.key) > -1;;
                        modalProps.togglePinned = togglePinned;
                    } else {
                        // in case no tile data is found
                        setVisibleModal(null);
                    }
                    break;
            }

            if (isMobileOrTablet) {
                return (
                    <MobileModal
                        {...modalProps}
                        onCancel={toggleModal}
                    />
                );
            }

            return (
                <Modal
                    title={modalProps.title}
                    visible={true}
                    onCancel={toggleModal}
                    footer={modalProps.footer}
                >
                    {modalProps.modalBody}
                </Modal>
            )
        }
    };

    return (
        <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
                <div className={`${appStyles}`}>
                    <Flexbox style={{minHeight: '100%'}}>
                        {(!isMobileOrTablet || (isMobileOrTablet && showSidebar)) &&
                        <Sidebar
                            id="sidebar"
                            tiles={tilesData}
                            pinned={getPinnedTiles()}
                            menus={menusData}
                            userData={userData}
                            isMobileOrTablet={isMobileOrTablet}
                            toggleSidebar={toggleSidebar}
                            toggleModal={toggleModal}
                        />
                        }
                        <Flexbox className={`content-container ${css`width:100%`}`} flexDirection={'column'} flexGrow={1}>
                            <Switch>
                                <Route exact path="/">
                                    {renderHeader(true, visibleModal !== null)}
                                    {!(isMobileOrTablet && visibleModal !== null) &&
                                    <Flexbox className={`tiles-container ${contentStyles}`} flexDirection={'column'}>
                                        {renderTileLists()}
                                    </Flexbox>
                                    }
                                </Route>
                                <Route exact path="/organization">
                                    {renderHeader(false)}
                                    {!(isMobileOrTablet && visibleModal !== null) &&
                                        <Organization apiUrl={apiBaseUrl} isMobileOrTablet={isMobileOrTablet} />
                                    }
                                </Route>
                                <Route exact path="/news-archive">
                                    {renderHeader(false)}
                                    {!(isMobileOrTablet && visibleModal !== null) &&
                                        <NewsArchive apiUrl={apiBaseUrl} />
                                    }
                                </Route>
                                <Redirect to="/" />
                            </Switch>
                            {renderModal()}
                        </Flexbox>
                    </Flexbox>
                </div>
            </QueryParamProvider>
        </Router>
    );
};

export default App;
