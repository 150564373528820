
import React from 'react';
import Flexbox from '@app/components/Flexbox';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';

import Icon from '@app/components/Icon';

import logo from '@app/images/ARM_Logo_White.png';

const insideLogoStyles = css`
    font-size: 32px;
    line-height: 32px;
    span {
        font-family: Open Sans Condensed, Roboto, Helvetica, Arial, sans-serif;
        letter-spacing: 4px;
        margin-left: 4px;
        padding-bottom: 1px;
    }
`;

const logoStyles = css`
    width: 160px;
    border-bottom: 1px solid white;
    padding: 0 10px 10px 10px;
    margin-bottom: 20px;
    max-height: 54px;
`;

const mobileLogoStyles = css`
    max-height: 20px;
`;

const mobileIStyles = css`
    font-size: 14px;
    line-height: 14px;
    span {
        font-family: Open Sans Condensed, Roboto, Helvetica, Arial, sans-serif;
        letter-spacing: 2px;
        margin-left: 2px;
        padding-bottom: 1px;
    }
`;

const Logo = ({mobile}) => {
    const logoClass = mobile ? mobileLogoStyles : logoStyles;
    const iClass = mobile ? mobileIStyles : insideLogoStyles;
    return (
        <Flexbox flexDirection="column">
            <Link to="/" style={{color: 'white'}}>
                <Flexbox className={`inside-logo ${iClass}`} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                    <Icon icon="info-circle" solid/><span>nside</span>
                </Flexbox>
            </Link>
            <Link to="/">
                <Flexbox className={'logo-container'} flexDirection={'row'} justifyContent={'center'}>
                    <img className={`site-logo ${logoClass}`} src={logo} alt={'Inside ARM'}/>
                </Flexbox>
            </Link>
        </Flexbox>
    );
}

export default Logo
