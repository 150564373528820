import React from 'react';
import PropTypes from 'prop-types';
import { PushpinOutlined, PushpinFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Icons from '@fortawesome/free-regular-svg-icons';
import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import * as BrandIcons from '@fortawesome/free-brands-svg-icons';

const iconList = [];
Object.keys(Icons).filter(key => key !== "far" && key !== "prefix" ).forEach(icon => iconList.push(Icons[icon]));
Object.keys(SolidIcons).filter(key => key !== "fas" && key !== "prefix" ).forEach(icon => iconList.push(SolidIcons[icon]));
Object.keys(BrandIcons).filter(key => key !== "fab" && key !== "prefix" ).forEach(icon => iconList.push(BrandIcons[icon]));
library.add(...iconList);

// font awesome doesn't have free outline "pin" icon so use antd for this icon,
// but has a much bigger library that we want to utilize for other list items
export default class Icon extends React.Component {
    static propTypes = {
        icon: PropTypes.string,
        solid: PropTypes.bool, // solid vs 'regular' outline
        iconClass: PropTypes.string // or pass in entire font awesome class names
    };

    static defaultProps = {
        solid: false,
        type: 'fa'
    };

    render() {
        let icon = null;

        if (this.props.icon === 'pushpin') {
            // font awesome's outline thumbtack isn't a free icon, so use antd for pinned icon
            icon = this.props.solid ? <PushpinFilled/> : <PushpinOutlined/>;

        } else if (this.props.iconClass) {
            const iconProps = this.props.iconClass.split(' ');
            icon = (
                <FontAwesomeIcon
                    icon={[iconProps[0], iconProps[1].replace('fa-', '')]}
                />
            )
        } else if (this.props.icon) {
            const theme = this.props.solid ? 'fas' : 'far';
            icon = (
                <FontAwesomeIcon
                    icon={[theme, this.props.icon]}
                />
            )
        }

        return icon;
    }
}
