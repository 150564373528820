import React from 'react';
import ReactDOM from 'react-dom';

import App from '@app/components/App';

import '@app/styles/main.less';

class Application {

    start() {
        console.log(`Starting i.arm app...`);

        ReactDOM.render(
            <App />,
            document.getElementById('root')
        );
    }
}

new Application().start();